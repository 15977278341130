"use client";

import ErrorScreen from "@/components/ui/error-screen";
import { oktah } from "@/styles/font";
import "./globals.css";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  return <html lang="en" data-theme="prime" className={`${oktah.variable} font-sans`} data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className="flex h-screen flex-col items-center justify-center gap-6">
        <ErrorScreen error={error} data-sentry-element="ErrorScreen" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}